<template>
  <div class="order_import_page">
    <el-alert
      class="order_import_alert"
      type="info"
      :closable="false"
    >
      <div
        slot
        style="line-height: 16px; font-size: 14px"
      >
        <div style="font-size: 16px; font-weight: bold; margin-bottom: 4px;">导入发货说明：</div>
        <div>1、导入文件最多不超过1000条</div>
        <div>2、导入前，请注意填写物流公司和运单号，发货后10天内仅支持做1次修改</div>
        <div>3、导入时，请注意：若1笔订单本次只发部分商品，请务必在导入预览时关联商品，否则系统将默认为整单发货，整单发货后不可再次对该订单进行发货操作</div>
        <div>
          <el-button
            type="text"
            @click="download('template')"
          >
            下载发货模板
          </el-button>
          <el-upload
            class="upload_btn"
            :action="$api.deliver_center.download_import"
            :headers="headers"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-progress="uploading"
            :on-success="importSuccess"
            :on-error="importError"
          >
            <el-button
              type="text"
            >
              导入发货文件
            </el-button>
          </el-upload>
          <el-button
            style="float: right"
            type="text"
            @click="download('explain')"
          >
            下载发货使用说明
          </el-button>
        </div>
      </div>
    </el-alert>
    <div>
      <div style="font-size: 16px; line-height: 18px; font-weight: bold; margin: 30px 0 4px;">
        导入文件预览：
      </div>
      <el-table
        class="my_table"
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        style="width: 100%;"
        element-loading-text="拼命加载中"
        row-key="index"
      >
        <el-table-column
          label="编号"
          prop="serialNo"
          width="50"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
        />
        <el-table-column
          prop="receiveInfo"
          label="收货信息"
          min-width="250px"
        ></el-table-column>
        <el-table-column
          prop="logisticName"
          label="物流公司"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="logisticNo"
          label="运单号"
        >
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注"
        ></el-table-column>
        <el-table-column
          prop="systemNotice"
          label="系统提示"
          min-width="250px"
        >
          <template slot-scope="{row}">
            <div v-html="formatLineFeed(row.systemNotice)"></div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="130"
        >
          <template slot-scope="{row}">
            <template>
              <el-link
                type="primary"
                @click="associatedGoods(row)"
              >
                关联商品
              </el-link>
              <el-divider direction="vertical"></el-divider>
            </template>
            <el-link type="danger" @click="deleteList(row)">
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <div v-if="tableData.length">
        <el-button
          size="small"
          @click="clearTable"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitImport"
        >
          确认导入
        </el-button>
      </div>
    </div>

    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="orderData"
      type="edit"
    />
  </div>
</template>

<script>
import DeliverOrderDialog from './components/deliver-order-dialog.vue';
import { downloadFile } from '@/utils/download'
export default {
  name: '',
  components: {
    DeliverOrderDialog
  },
  props: {},
  data() {
    return {
      loading: false,
      tableData: [],
      showDeliverModel: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      orderData: null, // 订单信息
      headers: {
        Authorization: localStorage.getItem('token'),
        commonParam: localStorage.getItem('projectCode') ? localStorage.getItem('projectCode') : 3300
      },
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.getList()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getListChange() {
      this.pagination.page = 1
      this.getList()
    },
    getList() { // index为页面当前页数
      let _this = this
      _this.loading = true
      _this.$axios.post(_this.$api.deliver_center.download_import_page,
        {
          currentPage: _this.pagination.page,
          pageSize: _this.pagination.page_count
        }
      ).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records;
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    },
    // 关联商品
    associatedGoods(row) {
      this.orderData = row;
      this.showDeliverModel = true;
    },
    // 清空表格
    clearTable() {
      this.$confirm('是否确认清除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.deliver_center.download_import_cancel).then((res) => {
          if (res.code === 0) {
            this.getListChange();
          }
        })
      }).catch(() => {});
    },
    // 确认导入
    submitImport() {
      this.$confirm('是否确认导入发货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.deliver_center.download_import_submit).then((res) => {
          if (res.code === 0) {
            this.getListChange();
            this.$alert('发货成功！页面自动清空', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              callback: action => {
                console.log(action)
              }
            });
          } else {
            this.$alert(`
            <div style="max-height: 400px; overflow: auto; white-space: pre-line;">
              ${res.msg.replace(/\*/g, '<span style="color: red">*</span>')}
            </div>
            `, '导入失败', {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true,
              customClass: 'my_import_goods_msg_box'
            });
            setTimeout(() => {
              this.$message.close();
            }, 100)
          }
        })
      }).catch(() => {});
    },
    // 下载模板
    download(type) {
      if (type === 'template') {
        let api = this.$api.deliver_center.download_template;
        this.$axios({
          method: 'get',
          url: api,
          responseType: 'blob'
        }).then((res) => {
          downloadFile(res, '导入模板.xls')
        })
      } else if (type === 'explain') {
        let api = this.$api.deliver_center.download_instructions;
        this.$axios({
          method: 'post',
          url: api
        }).then((res) => {
          if (res.code === 0) {
            window.open(res.data, '_blank')
          }
        })
      }
    },
    // 导入模板
    importExl() {

    },
    // 批量导入
    beforeAvatarUpload(file) {
    },
    uploading() {
      this.loading = true
    },
    importSuccess(res) {
      this.loading = false
      if (res.code === 500) {
        return this.$message.error('导入错误，请以正确格式填写文件')
      }
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$message({
        message: '导入成功',
        type: 'success',
        duration: 500,
        onClose: () => {
          this.getListChange()
        }
      })
    },
    importError(err) {
      this.loading = false
      this.$message.error('服务器错误，导入失败')
    },
    // 删除订单
    deleteList(row) {
      this.$confirm('是否确删除记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(this.$api.deliver_center.download_import_delete,
          {
            params: {
              id: row.id
            }
          }
        ).then((res) => {
          if (res.code === 0) {
            this.getList();
          }
        })
      }).catch(() => {});
    },
    formatLineFeed(value) {
      return value.replace(/\*/g, '<span style="color: red">*</span>')
    }
  }
}
</script>

<style lang="scss">
.order_import_alert {
  .el-alert__content {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
.upload_btn {
  display: inline-block;
  margin-left: 10px;
}
.my_table ::v-deep .cell {
  white-space: pre-line;
}
</style>
<style>
.el-message-box__wrapper .my_import_goods_msg_box {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}
</style>
