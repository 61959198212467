var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showoff
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showoff,
            title: "修改运单号",
            "custom-class": "modify-single-order",
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showoff = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "old-info",
              staticStyle: { "margin-bottom": "30px" },
            },
            [
              _c("div", { staticClass: "row-label" }, [
                _vm._v(" 旧运单信息： "),
              ]),
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    staticStyle: { "margin-right": "20px" },
                  },
                  [
                    _c("div", { staticClass: "label" }, [_vm._v(" 运单号： ")]),
                    _c("div", { staticClass: "con" }, [
                      _vm._v(" " + _vm._s(_vm.row.logisticNo) + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v(" 物流公司： ")]),
                  _c("div", { staticClass: "con" }, [
                    _vm._v(" " + _vm._s(_vm.row.logisticName) + " "),
                  ]),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "new-info" }, [
            _c("div", { staticClass: "row-label" }, [_vm._v(" 新运单信息： ")]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "nb-form",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: { columns: _vm.columns },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "logisticCode",
                              fn: function () {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.row.logisticCode ===
                                          "supplier-self",
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择物流公司",
                                      },
                                      model: {
                                        value: _vm.forms.logisticName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.forms,
                                            "logisticName",
                                            $$v
                                          )
                                        },
                                        expression: "forms.logisticName",
                                      },
                                    },
                                    _vm._l(_vm.logisticList, function (item) {
                                      return _c("el-option", {
                                        key: item.logisticId,
                                        attrs: {
                                          label: item.logisticName,
                                          value: item.logisticName,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "memoImg",
                              fn: function () {
                                return [
                                  _c("UpImageNew", {
                                    attrs: {
                                      "image-list": _vm.forms.memoImg,
                                      type: "string",
                                      num: 5,
                                    },
                                    on: {
                                      "update:imageList": function ($event) {
                                        return _vm.$set(
                                          _vm.forms,
                                          "memoImg",
                                          $event
                                        )
                                      },
                                      "update:image-list": function ($event) {
                                        return _vm.$set(
                                          _vm.forms,
                                          "memoImg",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          497282837
                        ),
                        model: {
                          value: _vm.forms,
                          callback: function ($$v) {
                            _vm.forms = $$v
                          },
                          expression: "forms",
                        },
                      },
                      "nb-form",
                      _vm.formInfo,
                      false
                    ),
                    _vm.formEvents
                  )
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }