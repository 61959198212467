var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showoff
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showoff,
            title: "批量修改运单号",
            "custom-class": "modify-batch-order",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showoff = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "hander-batch" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showBatch,
                      expression: "showBatch",
                    },
                  ],
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.tapBatchFill },
                },
                [_vm._v(" 批量填充 ")]
              ),
            ],
            1
          ),
          _c(
            "nb-table",
            _vm._b(
              {
                attrs: { "table-list": _vm.list },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-newLogisticName",
                      fn: function () {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "mini",
                                filterable: "",
                                clearable: "",
                                placeholder: "新物流公司",
                              },
                              model: {
                                value: _vm.logisticName,
                                callback: function ($$v) {
                                  _vm.logisticName = $$v
                                },
                                expression: "logisticName",
                              },
                            },
                            _vm._l(_vm.logisticList, function (item) {
                              return _c("el-option", {
                                key: item.logisticId,
                                attrs: {
                                  label: item.logisticName,
                                  value: item.logisticName,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "header-newLogisticNo",
                      fn: function () {
                        return [
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "新运单号" },
                            model: {
                              value: _vm.logisticNo,
                              callback: function ($$v) {
                                _vm.logisticNo = $$v
                              },
                              expression: "logisticNo",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "header-newMemo",
                      fn: function () {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              maxlength: 350,
                              "show-word-limit": true,
                              placeholder: "备注",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.memo,
                              callback: function ($$v) {
                                _vm.memo = $$v
                              },
                              expression: "memo",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "header-memoImg",
                      fn: function () {
                        return [
                          _c("UpImageNew", {
                            attrs: {
                              "image-list": _vm.memoImg,
                              type: "string",
                              num: 5,
                            },
                            on: {
                              "update:imageList": function ($event) {
                                _vm.memoImg = $event
                              },
                              "update:image-list": function ($event) {
                                _vm.memoImg = $event
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "newLogisticName",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: !_vm._isRequired(row),
                                size: "mini",
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: row.forms.logisticName,
                                callback: function ($$v) {
                                  _vm.$set(row.forms, "logisticName", $$v)
                                },
                                expression: "row.forms.logisticName",
                              },
                            },
                            _vm._l(_vm.logisticList, function (item) {
                              return _c("el-option", {
                                key: item.logisticId,
                                attrs: {
                                  label: item.logisticName,
                                  value: item.logisticName,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "newLogisticNo",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm._isRequired(row),
                              size: "mini",
                              placeholder: "运单号",
                            },
                            model: {
                              value: row.forms.logisticNo,
                              callback: function ($$v) {
                                _vm.$set(row.forms, "logisticNo", $$v)
                              },
                              expression: "row.forms.logisticNo",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "newMemo",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              maxlength: 350,
                              "show-word-limit": true,
                              type: "textarea",
                              placeholder: "备注",
                            },
                            model: {
                              value: row.forms.memo,
                              callback: function ($$v) {
                                _vm.$set(row.forms, "memo", $$v)
                              },
                              expression: "row.forms.memo",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "memoImg",
                      fn: function ({ row }) {
                        return [
                          _c("UpImageNew", {
                            attrs: {
                              "image-list": row.forms.memoImg,
                              type: "string",
                              num: 5,
                            },
                            on: {
                              "update:imageList": function ($event) {
                                return _vm.$set(row.forms, "memoImg", $event)
                              },
                              "update:image-list": function ($event) {
                                return _vm.$set(row.forms, "memoImg", $event)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1575451926
                ),
              },
              "nb-table",
              _vm.tableInfo,
              false
            )
          ),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.saveLoad,
                  },
                  on: { click: _vm.tapSave },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.showoff = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }