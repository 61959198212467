// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.modify-single-order .old-info {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.modify-single-order .old-info .content {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.modify-single-order .old-info .item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.modify-single-order .new-info {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.modify-single-order .new-info .row-label {\n  padding-top: 6.5px;\n}\n.modify-single-order .new-info .item {\n  margin-bottom: 20px;\n}\n.modify-single-order .row-label {\n  width: 85px;\n  font-weight: bold;\n}\n.modify-single-order .nb-form {\n  margin-bottom: 0;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
