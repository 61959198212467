<template>
  <el-dialog
    v-if="showoff"
    :visible.sync="showoff"
    title="批量修改运单号"
    custom-class="modify-batch-order"
    width="1000px"
  >
    <div class="hander-batch">
      <el-button
        v-show="showBatch"
        type="primary"
        size="mini"
        @click="tapBatchFill"
      >
        批量填充
      </el-button>
    </div>

    <nb-table
      v-bind="tableInfo"
      :table-list="list"
    >
      <!-- 自定义标题 -->
      <template #header-newLogisticName>
        <el-select
          v-model="logisticName"
          size="mini"
          filterable
          clearable
          placeholder="新物流公司"
        >
          <el-option
            v-for="item in logisticList"
            :key="item.logisticId"
            :label="item.logisticName"
            :value="item.logisticName"
          />
        </el-select>
      </template>
      <template #header-newLogisticNo>
        <el-input
          v-model="logisticNo"
          size="mini"
          placeholder="新运单号"
        />
      </template>
      <template #header-newMemo>
        <el-input
          v-model="memo"
          size="mini"
          :maxlength="350"
          :show-word-limit="true"
          placeholder="备注"
          type="textarea"
        />
      </template>
      <template #header-memoImg>
        <UpImageNew
          :image-list.sync="memoImg"
          type="string"
          :num="5"
        />
      </template>
      <template #newLogisticName="{row}">
        <el-select
          v-model="row.forms.logisticName"
          :disabled="!_isRequired(row)"
          size="mini"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in logisticList"
            :key="item.logisticId"
            :label="item.logisticName"
            :value="item.logisticName"
          />
        </el-select>
      </template>
      <template #newLogisticNo="{row}">
        <el-input
          v-model="row.forms.logisticNo"
          :disabled="!_isRequired(row)"
          size="mini"
          placeholder="运单号"
        />
      </template>
      <template #newMemo="{row}">
        <el-input
          v-model="row.forms.memo"
          size="mini"
          :maxlength="350"
          :show-word-limit="true"
          type="textarea"
          placeholder="备注"
        />
      </template>
      <template #memoImg="{row}">
        <UpImageNew
          :image-list.sync="row.forms.memoImg"
          type="string"
          :num="5"
        />
      </template>
    </nb-table>

    <div class="btn-box">
      <el-button
        type="primary"
        size="mini"
        :loading="saveLoad"
        @click="tapSave"
      >
        保存
      </el-button>
      <el-button
        type="default"
        size="mini"
        @click="showoff = false"
      >
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { clone } from 'ldx-utils';
import { sleep } from '@/com';
import { modifyBatchOrder } from '@/utils/pageConfigs.js';
import UpImageNew from '@/components/common/upImageNew/index';

export default {
  components: {
    UpImageNew,
  },
  data() {
    modifyBatchOrder.call(this);
    return {
      showoff: false,
      list: [],
      logisticList: [],
      saveLoad: false,
      // 标题表单属性
      logisticName: '',
      logisticNo: '',
      memo: '',
      memoImg: '',
    };
  },
  computed: {
    showBatch() {
      if (this.logisticName || this.logisticNo || this.memo || this.memoImg) return true;
      return false;
    },
  },
  methods: {
    show(checks, logisticList) {
      this.logisticList = logisticList;
      const list = clone(checks);
      list.forEach((item) => {
        this.$set(item, 'forms', {
          logisticName: '', logisticNo: '', memo: '', memoImg: '', packageId: item.packageId,
        });
      });
      this.list = list;
      this.showoff = true;
    },
    hide() {
      this.showoff = false;
      this.logisticName = '';
      this.logisticNo = '';
      this.memo = '';
      this.memoImg = '';
    },
    _isRequired(item) {
      return item.logisticCode !== 'supplier-self';
    },
    // 批量填充
    tapBatchFill() {
      this.list.forEach((item) => {
        const isRequired = this._isRequired(item);
        if (this.logisticName && isRequired) item.forms.logisticName = this.logisticName;
        if (this.logisticNo && isRequired) item.forms.logisticNo = this.logisticNo;
        if (this.memo) item.forms.memo = this.memo;
        if (this.memoImg) item.forms.memoImg = this.memoImg;
      });
    },
    // 点击保存按钮
    async tapSave() {
      for (let item of this.list) {
        const isRequired = this._isRequired(item);

        if (!item.forms.logisticName && isRequired) {
          this.$message({ type: 'error', message: `序号为${item.indexVal}的订单还没选择物流公司` });
          return;
        }
        if (!item.forms.logisticNo && isRequired) {
          this.$message({ type: 'error', message: `序号为${item.indexVal}的订单还没填写运单号` });
          return;
        }
      }
      this.saveLoad = true;
      sleep(this.batch_edit);
    },
    async batch_edit() {
      const params = {
        editParamList: this.list.map((item) => item.forms),
      };
      const res = await this.$axios.post(this.$api.delivery.batch_edit, params);
      this.saveLoad = false;
      if (res.code === 0) {
        this.hide();
        this.$message({ type: 'success', message: '修改成功' });
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss">
.modify-batch-order{
  .el-dialog__body{padding-top: 10px;}
  .hander-batch{height: 28px; margin-bottom: 10px; text-align: right;}
  .nb-table{margin-bottom: 20px;}
  .btn-box{text-align: center;}
}
</style>
