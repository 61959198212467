<template>
  <div>
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="onSearch"
    >
      <div>
        <p>
          订单编号
        </p>
        <el-input
          v-model.trim="orderNo"
          placeholder="请输入订单编号"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          店铺名称
        </p>
        <el-input
          v-model.trim="storeName"
          placeholder="请输入关键字"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          商品ID
        </p>
        <el-input
          v-model.trim="goodsId"
          placeholder="请输入完整商品ID"
          size="mini"
          clearable
          type="number"
        />
      </div>

      <div>
        <p>
          商品名称
        </p>
        <el-input
          v-model.trim="goodsName"
          placeholder="请输入关键字"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人名称
        </p>
        <el-input
          v-model.trim="receiverName"
          placeholder="请输入完整收件人名称"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人手机号
        </p>
        <el-input
          v-model.trim="mobile"
          placeholder="请输入收件人手机号"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          下单时间
        </p>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        />
      </div>

      <div>
        <p>
          预警状态
        </p>
        <el-select
          v-model="notDeliveryType"
          size="mini"
          placeholder="请选择预警状态"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            label="24小时未发货"
            :value="1"
          />
          <el-option
            label="48小时未发货"
            :value="2"
          />
        </el-select>
      </div>

      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      default-expand-all
      :cell-style="{
        'background-color': '#f5f7fa'
      }"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-table
            class="interior-table"
            :data="row.tradeOrderGoodsVoList"
            size="small"
          >
            <el-table-column
              label="商品信息"
              width="300"
            >
              <template slot-scope="{row: goods}">
                <div class="goods-card">
                  <img :src="goods.goodsImage.split(',')[0]" />
                  <div class="content">
                    <p>{{ goods.goodsName }}</p>
                    <p class="sku">
                      ID:{{ goods.goodsId }}
                    </p>
                    <p class="sku">
                      规格：{{ goods.skuName }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="数量"
              prop="sellCount"
            />
            <el-table-column
              label="商品总价"
              prop="sellTotal"
            />
            <el-table-column
              label="店铺优惠"
              prop="discountFee"
            />
            <el-table-column
              label="实收金额"
              min-width="130px"
            >
              <template slot-scope="{row: goods}">
                <p>
                  {{ goods.supplierPayment }}
                </p>
                <p class="fee">
                  平台优惠：{{ goods.couponFee }}
                </p>
                <p class="fee">
                  活动优惠：{{ goods.promFee }}
                </p>
                <p class="fee">
                  含配送费：{{ goods.postFee }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="买家信息"
            >
              <template>
                <img
                  :src="row.tradeOrderVo.headPic"
                  width="50px"
                  height="50px"
                />
                <p>{{ row.tradeOrderVo.nickname }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="收件人"
            >
              <template>
                <p>{{ row.tradeOrderVo.receiverName }}</p>
                <p>{{ row.tradeOrderVo.mobile || '暂无' }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="已发货数/下单数"
              width="130"
            >
              <template slot-scope="{ row: goods }">
                <span>{{ Number(goods.shipmentCount) }} / {{ Number(goods.sellCount) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="订单编号">
        <template slot-scope="{row}">
          <span>{{ row.tradeOrderVo.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间">
        <template slot-scope="{row}">
          <span>{{ formatTime(row.tradeOrderVo.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
      >
        <template slot-scope="{row}">
          <el-button
            size="mini"
            type="primary"
            @click="toDetail(row)"
          >
            查看详情
          </el-button>
          <el-button
            size="mini"
            type="primary"
            :disabled="isDisabledDeliverBtn(row)"
            @click="onDeliverGoods(row)"
          >
            发货
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="orderData"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DeliverOrderDialog from './components/deliver-order-dialog.vue';

export default {
  components: {
    DeliverOrderDialog,
  },
  data() {
    return {
      loading: false,
      beginTime: '', // 查询-下单时间
      endTime: '',
      goodsId: '', // 查询-商品ID
      goodsName: '', // 查询-商品名称
      mobile: '', // 查询-收件人手机号
      orderNo: '', // 查询-订单编号
      orderStatus: 4, // 查询-订单状态
      receiverName: '', // 查询-收件人名称
      storeName: '', // 查询-店铺名称
      refundStatus: '', // 查询-售后状态
      logisticNo: '', // 查询-快递单号
      notDeliveryType: '', // 预警状态
      currentPage: 1, // 分页
      pageSize: 10,
      dateRange: [],
      tableData: [],
      count: 0,
      // 发货弹窗数据
      showDeliverModel: false,
      orderData: null,
    };
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    onSearch() {
      // 修改搜索条件时把页码重置为1
      this.currentPage = 1;
      this.getSearchList();
    },
    async getSearchList() {
      const [beginTime, endTime] = this.dateRange || [];
      let params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        deliveryStatus: 1, // 仅发货中心用:1-发货中心订单列表，0-否
        orderNo: this.orderNo || undefined,
        orderStatus: +this.orderStatus || undefined,
        goodsId: this.goodsId || undefined,
        goodsName: this.goodsName || undefined,
        logisticNo: this.logisticNo || undefined,
        mobile: this.mobile || undefined,
        receiverName: this.receiverName || undefined,
        refundStatus: this.refundStatus || undefined,
        beginTime,
        endTime,
      };
      this.loading = true;
      const { code, data } = await this.$axios.post(this.$api.order.orderList, params);
      this.loading = false;
      if (code !== 0) return;
      this.tableData = data.records;
      this.count = +data.total;
    },
    resetSearch() {
      Object.assign(this.$data, this.$options.data());
      this.getSearchList();
    },
    formatTime(time) {
      return time ? dayjs(+time).format('YYYY-MM-DD HH:mm:ss') : '-';
    },
    toDetail({ tradeOrderVo: { orderId } }) {
      const { href } = this.$router.resolve({
        path: '/nb/order/detail',
        query: {
          orderId,
        },
      });
      window.open(href, '_blank');
    },
    // 发货
    onDeliverGoods({ tradeOrderVo }) {
      this.orderData = tradeOrderVo;
      this.showDeliverModel = true;
    },
    // 发货成功
    deliverSuccess() {
      this.getSearchList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getSearchList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSearchList();
    },
    isDisabledDeliverBtn({ tradeOrderVo: { orderStatus, lastShippingTime } }) {
      return orderStatus !== 4 && orderStatus === 6 && !!lastShippingTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-card {
  min-width: 10px;
  width: 100%;
  display: flex;
  img {
    margin-top: 8px;
    width: 60px;
    height: 60px;
  }
  .content {
    padding: 0 12px;
    line-height: 28px;
    .sku{
      color: #736E6E;
    }
  }
}
.interior-table {
  width: calc(100% - 80px);
  margin-left: auto;
}
</style>
