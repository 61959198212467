var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showoff
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showoff,
            title: "物流详情",
            "custom-class": "logistics-details-dialog",
            top: "10vh",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showoff = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "details-box" },
            _vm._l(_vm.details, function (item) {
              return _c("div", { key: item.key, staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [
                  _c("b", [_vm._v(_vm._s(item.label) + "：")]),
                ]),
                item.type !== "image"
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(" " + _vm._s(_vm.pages[item.key]) + " "),
                    ])
                  : _vm._e(),
                item.type === "image" && _vm.pages[item.key]
                  ? _c(
                      "div",
                      _vm._l(_vm.pages[item.key].split(","), function (value) {
                        return _c("el-image", {
                          key: value,
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: {
                            src: value,
                            fit: "contain",
                            "preview-src-list": _vm.pages[item.key].split(","),
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "goods-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v(" 运单中的商品 ")]),
              _c(
                "div",
                { staticClass: "scroll-box" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      staticStyle: { height: "400px" },
                      attrs: { native: false },
                    },
                    _vm._l(_vm.goodsList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "goods-item" },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            [
                              _c("img", {
                                staticClass: "img",
                                attrs: { src: item.goodsImage, alt: "" },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(" " + _vm._s(item.goodsName) + " "),
                            ]),
                            _c("div", { staticClass: "num" }, [
                              _vm._v(" x" + _vm._s(item.shipmentCount) + " "),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "progress-box" },
              [
                _c("div", { staticClass: "title" }, [_vm._v(" 物流进度 ")]),
                _c(
                  "el-scrollbar",
                  {
                    staticStyle: { height: "400px" },
                    attrs: { native: false },
                  },
                  [
                    _c(
                      "el-timeline",
                      _vm._l(_vm.activities, function (item, index) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: index,
                            attrs: {
                              size: "large",
                              timestamp: item.timestamp,
                              icon: item.icon,
                              color: item.color,
                            },
                          },
                          [_c("div", [_vm._v(_vm._s(item.context))])]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }