<template>
  <div class="app-container">
    <div class="outer">
      <el-tabs
        v-model="activeName"
        type="border-card"
      >
        <el-tab-pane
          label="待发货"
          name="1"
        >
          <OrderList v-if="activeName === '1'" />
        </el-tab-pane>

        <el-tab-pane
          label="导入发货"
          name="2"
        >
          <import-order v-if="activeName === '2'" />
        </el-tab-pane>

        <el-tab-pane
          label="已发货"
          name="3"
        >
          <already-list v-if="activeName === '3'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import OrderList from './orderList.vue';
import ImportOrder from './order-import.vue';
import alreadyList from './already-list.vue';

export default {
  name: '',
  components: {
    OrderList,
    ImportOrder,
    alreadyList,
  },
  props: {},
  data() {
    return {
      activeName: '1',
    };
  },
};
</script>
