// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.logistics-details-dialog .details-box {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 20px;\n}\n.logistics-details-dialog .details-box .item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-right: 40px;\n}\n.logistics-details-dialog .container {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 10px;\n  padding-right: 0;\n  font-size: 12px;\n  border: #ccc solid 1px;\n}\n.logistics-details-dialog .title {\n  margin-bottom: 20px;\n  font-size: 14px;\n}\n.logistics-details-dialog .goods-box {\n  width: 50%;\n}\n.logistics-details-dialog .goods-box .img {\n  width: 40px;\n  height: 40px;\n}\n.logistics-details-dialog .goods-box .name {\n  max-height: 24px;\n  margin-right: 5px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n.logistics-details-dialog .goods-box .num {\n  white-space: nowrap;\n}\n.logistics-details-dialog .goods-box .scroll-box {\n  padding-right: 10px;\n  border-right: #ccc solid 1px;\n}\n.logistics-details-dialog .goods-box .el-scrollbar__wrap {\n  overflow-x: hidden;\n}\n.logistics-details-dialog .content {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 330px;\n}\n.logistics-details-dialog .goods-item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 10px;\n}\n.logistics-details-dialog .progress-box {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  padding-left: 10px;\n}\n.logistics-details-dialog .progress-box .el-scrollbar__wrap {\n  padding-left: 5px;\n  padding-right: 5px;\n  overflow-x: hidden;\n}\n.logistics-details-dialog .el-timeline-item__content {\n  line-height: 16px;\n  font-size: 12px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
