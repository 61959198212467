var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order_import_page" },
    [
      _c(
        "el-alert",
        {
          staticClass: "order_import_alert",
          attrs: { type: "info", closable: false },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "16px", "font-size": "14px" },
              attrs: { slot: "" },
              slot: "default",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "16px",
                    "font-weight": "bold",
                    "margin-bottom": "4px",
                  },
                },
                [_vm._v("导入发货说明：")]
              ),
              _c("div", [_vm._v("1、导入文件最多不超过1000条")]),
              _c("div", [
                _vm._v(
                  "2、导入前，请注意填写物流公司和运单号，发货后10天内仅支持做1次修改"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "3、导入时，请注意：若1笔订单本次只发部分商品，请务必在导入预览时关联商品，否则系统将默认为整单发货，整单发货后不可再次对该订单进行发货操作"
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.download("template")
                        },
                      },
                    },
                    [_vm._v(" 下载发货模板 ")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload_btn",
                      attrs: {
                        action: _vm.$api.deliver_center.download_import,
                        headers: _vm.headers,
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
                        "show-file-list": false,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-progress": _vm.uploading,
                        "on-success": _vm.importSuccess,
                        "on-error": _vm.importError,
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v(" 导入发货文件 "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.download("explain")
                        },
                      },
                    },
                    [_vm._v(" 下载发货使用说明 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                "line-height": "18px",
                "font-weight": "bold",
                margin: "30px 0 4px",
              },
            },
            [_vm._v(" 导入文件预览： ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "my_table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": {
                  background: "#D9DDE1",
                  color: "#0D0202",
                  "font-weight": 400,
                },
                "element-loading-text": "拼命加载中",
                "row-key": "index",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "编号",
                  prop: "serialNo",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "订单号" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveInfo",
                  label: "收货信息",
                  "min-width": "250px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logisticName",
                  label: "物流公司",
                  width: "110",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "logisticNo", label: "运单号" },
              }),
              _c("el-table-column", { attrs: { prop: "memo", label: "备注" } }),
              _c("el-table-column", {
                attrs: {
                  prop: "systemNotice",
                  label: "系统提示",
                  "min-width": "250px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatLineFeed(row.systemNotice)
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.associatedGoods(row)
                                },
                              },
                            },
                            [_vm._v(" 关联商品 ")]
                          ),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                        ],
                        _c(
                          "el-link",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteList(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.page_count,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.item_total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
          _vm.tableData.length
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.clearTable } },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.submitImport },
                    },
                    [_vm._v(" 确认导入 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("DeliverOrderDialog", {
        attrs: { "order-data": _vm.orderData, type: "edit" },
        model: {
          value: _vm.showDeliverModel,
          callback: function ($$v) {
            _vm.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }