<template>
  <el-dialog
    v-if="showoff"
    :visible.sync="showoff"
    title="修改运单号"
    custom-class="modify-single-order"
    width="650px"
  >
    <div
      class="old-info"
      style="margin-bottom: 30px;"
    >
      <div class="row-label">
        旧运单信息：
      </div>
      <div class="content">
        <div
          class="item"
          style="margin-right: 20px;"
        >
          <div class="label">
            运单号：
          </div>
          <div class="con">
            {{ row.logisticNo }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            物流公司：
          </div>
          <div class="con">
            {{ row.logisticName }}
          </div>
        </div>
      </div>
    </div>
    <div class="new-info">
      <div class="row-label">
        新运单信息：
      </div>
      <div class="content">
        <nb-form
          v-model="forms"
          :columns="columns"
          v-bind="formInfo"
          v-on="formEvents"
        >
          <template #logisticCode>
            <el-select
              v-model="forms.logisticName"
              :disabled="row.logisticCode === 'supplier-self'"
              filterable
              clearable
              placeholder="请选择物流公司"
            >
              <el-option
                v-for="item in logisticList"
                :key="item.logisticId"
                :label="item.logisticName"
                :value="item.logisticName"
              />
            </el-select>
          </template>
          <template #memoImg>
            <UpImageNew
              :image-list.sync="forms.memoImg"
              type="string"
              :num="5"
            />
          </template>
        </nb-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { createInput } from 'nb-modules';
import { isEmpty } from 'ldx-utils';
import { modifySingleOrder } from '@/utils/pageConfigs.js';
import { sleep, trim, setTrim } from '@/com';
import UpImageNew from '@/components/common/upImageNew/index';

export default {
  components: {
    UpImageNew,
  },
  data() {
    modifySingleOrder.call(this);
    return {
      showoff: false,
      forms: {},
      logisticList: [],
      row: {},
      logisticNo: '',
    };
  },
  computed: {
    columns() {
      const isRequired = this.row.logisticCode !== 'supplier-self';
      return [
        createInput('运单号', 'logisticNo', {
          required: isRequired,
          events: {
            input: (val) => {
              const value = val.replace(/\s+/g, '');

              if (value !== this.logisticNo) {
                sleep(this.getLogisticName, { args: [value], debounce: true });
              }

              this.forms.logisticNo = value;
              this.logisticNo = this.forms.logisticNo;
            },
          },
        }),
        {
          slot: 'logisticCode',
          label: '物流公司',
          key: 'logisticName',
          value: '',
          required: isRequired,
          rules: [
            {
              validator: isEmpty, trigger: 'change', message: '请选择物流公司', required: true,
            },
          ],
        },
        createInput('备注', 'memo', {
          maxlength: 350, 'show-word-limit': true, type: 'textarea', rows: '5',
        }),
        {
          slot: 'memoImg',
          label: '备注图片',
          key: 'memoImg',
          value: '',
        },
      ];
    },
  },
  methods: {
    show(logisticList, row) {
      this.logisticList = logisticList;
      this.row = row;
      this.showoff = true;

      setTimeout(() => {
        for (let key in this.forms) {
          if (row[key]) this.forms[key] = row[key];
        }
        this.logisticNo = row.logisticNo;
      });
    },
    hide() {
      this.showoff = false;
    },
    onsubmit() {
      sleep(this.modify);
    },
    // 修改运单号接口
    async modify() {
      const formsVal = setTrim(this.forms);
      const params = {
        editParamList: [{ ...formsVal, packageId: this.row.packageId }],
      };
      const res = await this.$axios.post(this.$api.delivery.batch_edit, params);
      this.$refs.nbForm.resetLoad();
      if (res.code === 0) {
        this.hide();
        this.$message({ type: 'success', message: '修改成功' });
        this.$emit('submit');
      }
    },
    // 根据单号获取快递公司
    async getLogisticName(logisticNo) {
      const params = { logisticNo };
      const res = await this.$axios.get(this.$api.deliver_center.download_logisticByNo, { params });
      if (res.code === 0) {
        const { logisticName } = res.data || {};
        this.forms.logisticName = logisticName || '';
      } else {
        this.forms.logisticName = '';
      }
    },
  },
};
</script>

<style lang="scss">
.modify-single-order{
  .old-info{
    display: flex;
    .content{display: flex;}
    .item{display: flex; align-items: center;}
  }
  .new-info{
    display: flex;
    .row-label{padding-top: 6.5px;}
    .item{margin-bottom: 20px;}
  }
  .row-label{width: 85px; font-weight: bold;}
  .nb-form{margin-bottom: 0;}
}
</style>
