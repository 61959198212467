var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container yfh-page" },
    [
      _c(
        "div",
        { staticClass: "app-item", staticStyle: { "margin-bottom": "50px" } },
        [
          _c(
            "nb-form",
            _vm._g(
              _vm._b(
                {
                  scopedSlots: _vm._u([
                    {
                      key: "logisticCode",
                      fn: function () {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择物流公司",
                              },
                              model: {
                                value: _vm.forms.logisticCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "logisticCode", $$v)
                                },
                                expression: "forms.logisticCode",
                              },
                            },
                            _vm._l(_vm.logisticList, function (item) {
                              return _c("el-option", {
                                key: item.logisticId,
                                attrs: {
                                  label: item.logisticName,
                                  value: item.logisticCode,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.forms,
                    callback: function ($$v) {
                      _vm.forms = $$v
                    },
                    expression: "forms",
                  },
                },
                "nb-form",
                _vm.formInfo,
                false
              ),
              _vm.formEvents
            )
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-item" },
        [
          _c(
            "div",
            { staticClass: "tip-box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.tapModifyBatch },
                },
                [_vm._v("批量修改运单号")]
              ),
              _c("el-alert", {
                attrs: {
                  title: "订单在发货后10天内仅可修改1次运单号，请谨慎操作",
                  type: "error",
                  closable: false,
                },
              }),
            ],
            1
          ),
          _c(
            "nb-table",
            _vm._b(
              {
                scopedSlots: _vm._u([
                  {
                    key: "handles",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.tapDetail(row)
                              },
                            },
                          },
                          [_vm._v("物流详情")]
                        ),
                        row.canModify
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.tapModifySingle(row)
                                  },
                                },
                              },
                              [_vm._v("修改运单号")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              "nb-table",
              _vm.tableInfo,
              false
            )
          ),
        ],
        1
      ),
      _c("logistics-details", { ref: "logisticsDetails" }),
      _c("modify-single", {
        ref: "modifySingleRef",
        on: { submit: _vm.submitSingle },
      }),
      _c("modify-batch", {
        ref: "modifyBatchleRef",
        on: { submit: _vm.submitSingle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }